<template>
  <v-app>
    <div
      class="bg-body d-flex flex-column justify-center align-center min-vh-100"
    >
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <v-img
            src="@/assets/images/logo-white-horizontal.svg"
            height="80px"
            contain
            class="mb-10"
          />
          <div class="text-center mb-6">
            <h3 class="mb-2">Bem vindo de volta</h3>
            <h5 class="text-sm font-600 mb-0 grey--text text--darken-4">
              Faça login para continuar
            </h5>
          </div>
          <v-form v-model="valid">
            <div class="mb-4">
              <v-text-field
                v-model="form.email"
                label="Email"
                type="email"
                placeholder="example@mail.com"
                outlined
                dense
                hide-details=""
                :rules="emailRules"
                class="mb-4"
                :disabled="loading"
              />
            </div>
            <div class="mb-4">
              <v-text-field
                v-model="form.password"
                label="Senha"
                type="password"
                placeholder="*********"
                outlined
                dense
                hide-details=""
                :disabled="loading"
                class="mb-4"
                :rules="passwordRules"
                @keyup.enter="processLogin"
              />
            </div>
          </v-form>

          <v-alert v-if="error" type="error" class="mb-4">
            {{ error }}
          </v-alert>

          <div class="mb-4 d-flex flex-column justify-center">
            <v-btn
              block
              color="primary"
              class="text-capitalize font-600"
              :disabled="!valid"
              :loading="loading"
              @click="processLogin"
            >
              Entrar
            </v-btn>
            <v-btn
              class="mt-3"
              text
              small
              :disabled="loading"
              @click="startResetPassword"
            >
              Recuperar senha
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="resetPassword.modal" max-width="500px">
      <v-card>
        <v-card-title class="headline">Recuperar senha</v-card-title>
        <v-card-text>
          <v-form v-model="resetPassword.valid" v-if="!resetPassword.success">
            <v-text-field
              v-model="resetPassword.email"
              label="Email"
              type="email"
              outlined
              :rules="[
                (v) => !!v || 'Email é obrigatório',
                (v) => /.+@.+\..+/.test(v) || 'Email deve ser válido',
              ]"
            />
            <v-alert v-if="resetPassword.error" type="error">
              {{ resetPassword.error }}
            </v-alert>
          </v-form>
          <v-alert v-else type="success">
            Email enviado com sucesso!
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="!resetPassword.valid"
            @click="resetPassword.modal = false"
          >
            {{ resetPassword.success ? "Fechar" : "Cancelar" }}
          </v-btn>
          <v-btn
            v-if="!resetPassword.success"
            color="primary"
            :disabled="!resetPassword.valid"
            :loading="resetPassword.loading"
            @click="processResetPassword"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import AUTH from "@/services/auth";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      checkbox: false,
      valid: false,
      loading: false,
      error: null,
      form: {
        email: "",
        password: "",
      },
      resetPassword: {
        modal: false,
        email: "",
        loading: false,
        error: null,
        valid: false,
        success: false,
      },
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
      ],
      passwordRules: [(v) => !!v || "Senha é obrigatória"],
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    startResetPassword() {
      this.resetPassword.email = this.form.email;
      this.resetPassword.modal = true;
    },
    async processResetPassword() {
      try {
        this.resetPassword.loading = true;
        this.resetPassword.error = null;
        const response = await AUTH.resetPassword.requestToken(
          this.resetPassword.email
        );
        if (!!response) this.resetPassword.success = true;
      } catch (error) {
        console.log(error);
        this.resetPassword.error = error.message || error;
        this.resetPassword.loading = false;
      }
    },
    async processLogin() {
      try {
        this.loading = true;
        this.error = null;
        const response = await this.login(this.form);
        if (!!response) this.$router.push("/app");
      } catch (error) {
        console.log(error);
        this.error = error.message || error;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>
